<template>
  <div
    class="shueiJinJiouBanner"
    :style="`background-image: url(${bgImage})`"
  ></div>
</template>

<script>
export default {
  props: {
    bgImage: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.shueiJinJiouBanner {
  width: 100%;
  padding-bottom: 12.534%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
