var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shueiJinJiouItem"},[_c('router-link',{attrs:{"to":{
      name: 'ShueiJinJiouDetail',
      query: {
        title: _vm.item.location,
        id: _vm.item.sId
      }
    }}},[_c('div',{staticClass:"shueiJinJiouItem__img clickable",style:(`background-image: url(${_vm.baseURL}${_vm.item.image})`),attrs:{"alt":`${_vm.item.title}`}},[_c('div',{staticClass:"shueiJinJiouItem__hoverItem"},[_c('h3',{staticClass:"shueiJinJiouItem__title"},[_vm._v(_vm._s(_vm.item.title))])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }