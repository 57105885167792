<template>
  <div class="shueiJinJiouItem">
    <router-link
      :to="{
        name: 'ShueiJinJiouDetail',
        query: {
          title: item.location,
          id: item.sId
        }
      }"
    >
      <div
        :alt="`${item.title}`"
        class="shueiJinJiouItem__img clickable"
        :style="`background-image: url(${baseURL}${item.image})`"
      >
        <div class="shueiJinJiouItem__hoverItem">
          <h3 class="shueiJinJiouItem__title">{{ item.title }}</h3>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      // describ: "金瓜石礦山所長宿舍是金瓜石礦業事務所倒數第二任的所長三毛菊次郎（末代所長是戶田貢）所使用的宿舍。三毛菊次郎於1936年擔任金瓜石鑛山事務所所長。任內資助金瓜石公學校校舍興建，地方人士感念其貢獻，通稱此宿舍為「三毛菊次郎宅」，簡稱「三毛宅」。2015年1月14日，因其具有稀少性，同時見證金瓜石礦山發展歷史，獲指定為新北市市定古蹟。"
      // image: "upload/shueijinjui/d2ea1abdf711.jpg"
      // link: null
      // location: "金瓜石"
      // order: "0"
      // sId: "2"
      // title: "金瓜石礦山所長宿舍(俗稱三毛菊次郎宅)"
      // view: "人文景點"
      type: Object
    }
  },
  computed: {
    baseURL() {
      return process.env.VUE_APP_WEB_BASE
    }
  }
}
</script>

<style lang="scss">
.shueiJinJiouItem {
  width: 33.333%;
  padding: 0.9rem;

  &__img {
    padding-bottom: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }

  &__img:hover > &__hoverItem {
    opacity: 0.9;
  }

  &__hoverItem {
    font-size: 2.8rem;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #d0a805;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &__title {
    font-weight: 500;
    padding: 0 4rem;
    text-align: center;
    letter-spacing: 0.2em;
    line-height: 125%;
  }
}

@media (max-width: 768px) {
  .shueiJinJiouItem {
    width: 100%;
  }
}
</style>
