<template>
  <main>
    <ShueiJinJiouBanner :bgImage="pageItem.bgImage" />
    <ShueiJinJiouTitle :item="pageItem" />
    <section>
      <div class="shueiJinJiou container">
        <ShueiJinJiouItem
          v-for="item in filterDataWithPagination"
          :key="item.sId"
          :item="item"
        />

        <BasePagination
          :total="filterData.length"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="onCurrentPageChange"
          class="shueiJinJiou__pagination"
        />
        <hr class="shueiJinJiou__hr" />
      </div>
    </section>
    <ShueiJinJiouFooter :item="pageItem" />
  </main>
</template>

<script>
import ShueiJinJiouBanner from '@/components/ShueiJinJiou/ShueiJinJiouBanner.vue'
import ShueiJinJiouTitle from '@/components/ShueiJinJiou/ShueiJinJiouTitle.vue'
import ShueiJinJiouFooter from '@/components/ShueiJinJiou/ShueiJinJiouFooter.vue'
import ShueiJinJiouItem from '@/components/ShueiJinJiou/ShueiJinJiouItem.vue'
import BasePagination from '@/components/BasePagination.vue'

import ShueiJinJiouData from '@/assets/data/ShueiJinJiou'
// Api
import { apiGetShueiJinJui } from '@/api/webAPI'

export default {
  components: {
    ShueiJinJiouBanner,
    ShueiJinJiouTitle,
    ShueiJinJiouFooter,
    ShueiJinJiouItem,
    BasePagination
  },
  data() {
    return {
      ShueiJinJiouData,
      currentPage: 1,
      pageSize: 6,
      dataList: []
    }
  },
  methods: {
    async fetchData() {
      const { page } = this.$route.query
      this.currentPage = +page || 1
      const response = (await apiGetShueiJinJui()).data.data

      this.dataList = response
    },
    onCurrentPageChange(page) {
      const { name } = this.$route
      this.$router.push({
        name,
        query: {
          page
        }
      })
    }
  },
  computed: {
    pageItem() {
      const { name } = this.$route
      const item = this.ShueiJinJiouData.find(data => data.routeName === name)

      return item
    },
    filterData() {
      const filterResult = this.dataList.filter(
        data => data.location === this.pageItem.title
      )

      return filterResult
    },
    filterDataWithPagination() {
      const pageSize = this.pageSize
      const startIndex = pageSize * (this.currentPage - 1)
      const endIndex = pageSize * this.currentPage

      return this.filterData.slice(startIndex, endIndex)
    }
  },
  watch: {
    $route(to, from) {
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style lang="scss">
.shueiJinJiou {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-end;
  padding-top: 5.1rem;

  &__pagination {
    width: 100%;
    margin: 4rem auto;
  }

  &__hr {
    border: none;
    border-bottom: 1px dashed #d0a805;
    width: 100%;
    margin-bottom: 4rem;
  }
}
</style>
