<template>
  <section
    class="shueiJinJiouTitle"
    :style="`background-color: ${item.bgColor}`"
  >
    <h2 class="shueiJinJiouTitle__title">{{ item.title }}</h2>
  </section>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    }
  }
}
</script>

<style lang="scss">
.shueiJinJiouTitle {
  padding: 4rem 0;
  text-align: center;

  &__title {
    position: relative;
    display: inline-block;
    font-weight: 900;
    font-size: 2.2rem;
    line-height: 137.5%;
    text-align: center;
    letter-spacing: 0.77em;
    text-indent: 0.77em;
    color: #595757;
    padding-bottom: 0.7rem;
  }

  &__title::after {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% - 1.54em);
    height: 0.5rem;
    top: 100%;
    transform: translateX(0.77em);
    background-color: #d0a805;
  }
}
</style>
