<template>
  <section class="shueiJinJiouFooter">
    <WaterGoldItem
      v-for="item in filterItemList"
      :key="item.title"
      :item="item"
      class="shueiJinJiouFooter__item"
    />
  </section>
</template>

<script>
import WaterGoldItem from '@/components/Culture/WaterGold/WaterGoldItem.vue'
import ShueiJinJiouData from '@/assets/data/ShueiJinJiou'

export default {
  props: {
    item: {
      type: Object
    }
  },
  components: {
    WaterGoldItem
  },
  data() {
    return {
      itemList: ShueiJinJiouData
    }
  },
  computed: {
    filterItemList() {
      const filterList = this.itemList.filter(
        item => item.title !== this.item.title
      )
      return filterList
    }
  }
}
</script>

<style lang="scss">
.shueiJinJiouFooter {
  display: flex;
  flex-wrap: wrap;

  & * + * h3 {
    border-left: 1px dashed #d0a805;
  }

  &__item {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .shueiJinJiouFooter {
    &__item {
      width: 100%;
    }
  }
}
</style>
