<template>
  <router-link :to="{ name: item.routeName }" class="waterGoldItem clickable">
    <div
      :alt="`${item.title}`"
      class="waterGoldItem__img"
      :style="`background-image: url(${item.image})`"
    ></div>
    <h3
      class="waterGoldItem__title"
      :style="`background-color: ${item.bgColor}`"
    >
      {{ item.title }}
    </h3>
  </router-link>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({
        title: '水湳洞',
        bgColor: '#D4E2C9',
        image: require('@/assets/image/Culture/WaterGold/水湳洞.png'),
        routeName: 'ShueiNanDong'
      })
    }
  }
}
</script>

<style lang="scss">
.waterGoldItem {
  display: block;
  width: 33.333%;

  &__img {
    padding-bottom: 61.45%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__title {
    padding: 1.8rem;
    width: 100%;
    -webkit-writing-mode: vertical-lr;
    writing-mode: vertical-lr;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 137.5%;
    color: #595757;
    letter-spacing: 0.5em;
    min-height: 13.5rem;
  }
}

@media (max-width: 768px) {
  .waterGoldItem {
    width: 100%;
  }
}
</style>
